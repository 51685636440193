<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LessonActionsDrawer from "@/components/Lesson/LessonActionsDrawer.vue";
import LessonPagePreview from "@/components/Lesson/LessonPagePreview.vue";
import { useVuelidate } from "@vuelidate/core";
import StartOnlineLessonModal from "@/components/Course/StartOnlineLessonModal.vue";

export default {
  name: "LessonHeader",

  components: {
    StartOnlineLessonModal,
    LessonPagePreview,
    LessonActionsDrawer,
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      button_loader: false,
      show_mobile_action: false,
      show_preview: false,
      showStartModal: false,
    };
  },

  computed: {
    ...mapState({
      lesson: (state) => state.lesson.lesson,
      modules: (state) => state.course.modules,
      windowWidth: (state) => state.system.window_width,
      test: (state) => state.lesson.test,
    }),

    ...mapGetters({
      getLessonNumber: "course/getLessonNumber",
    }),
  },

  methods: {
    ...mapActions({
      editLessonInformation: "lesson/editLessonInformation",
      loadCourseUnitsLabels: "course/loadCourseUnitsLabels",
      createWebinarRoom: "lesson/createWebinarRoom",
      loadTestInformation: "lesson/loadTestInformation",
      stopWebinar: "webinar/stopWebinar",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    showPreview() {
      this.show_mobile_action = false;
      this.show_preview = true;
    },

    updateLesson() {
      this.show_mobile_action = false;

      if (this.lesson.type === "test") {
        this.loadTestInformation({
          course_id: this.$route.params.id,
          lesson_id: this.$route.params.lessonId,
        }).then((response) => {
          if (
            response.data.data.questions_attached <
            response.data.data.questions_count
          ) {
            this.setNotification({
              type: "error",
              name: "Тест не сохранен",
              description: `Количество добавленных вопросов не может быть менее ${response.data.data.questions_count}`,
            });
            return;
          }

          if (!this.lesson.name) {
            this.setNotification({
              type: "error",
              name: "Ошибка сохранения",
              description: "Заполните название урока",
            });
            return;
          }

          if (this.lesson.name.length > 150) {
            this.setNotification({
              type: "error",
              name: "Ошибка сохранения",
              description: "Название урока превышает лимит",
            });
            return;
          }

          let data = structuredClone(this.lesson.data);
          if (!this.button_loader) {
            this.button_loader = true;

            this.editLessonInformation({
              course_id: this.$route.params.id,
              id: this.lesson.id,
              data: {
                name: this.lesson.name,
                description: this.lesson.description,
                data: data,
              },
            })
              .then(() => {
                this.$router.push({ name: "CourseView" });
              })
              .finally(() => {
                this.button_loader = false;
              });
          }
        });

        return;
      }

      if (!this.lesson.name) {
        this.setNotification({
          type: "error",
          name: "Ошибка сохранения",
          description: "Заполните название урока",
        });
        return;
      }

      if (this.lesson.name.length > 150) {
        this.setNotification({
          type: "error",
          name: "Ошибка сохранения",
          description: "Название урока превышает лимит",
        });
        return;
      }

      let data = structuredClone(this.lesson.data);
      let has_error = false;

      if (this.lesson.type === "lesson" || this.lesson.type === "task") {
        for (let i in data) {
          if (data[i].type.includes("-list")) {
            data[i].value = data[i].value.filter((value) => value.length);

            if (data[i].value.length === 0) {
              data[i].value = "";
            }
          }

          if (data[i].type === "image") {
            data[i].value = data[i].value.filter((image) => image.url);

            if (data[i].value.length === 0) {
              data[i].value = "";
            }
          }

          if (data[i].type === "question") {
            if (
              !data[i].value.name ||
              data[i].value.answers.filter((item) => item.length).length !==
                data[i].value.answers.length
            ) {
              document.getElementById(`validate-question-${i}`).click();

              this.setNotification({
                type: "error",
                name: "Ошибка сохранения",
                description: "Проверьте заполение вопроса с автопроверкой",
              });

              has_error = true;
              break;
            }
          }

          if (data[i].type === "test") {
            if (
              !data[i].value.name ||
              data[i].value.answers.filter((item) => item.length).length !==
                data[i].value.answers.length ||
              !data[i].value.selected.length
            ) {
              this.setNotification({
                type: "error",
                name: "Ошибка сохранения",
                description: "Проверьте заполение теста",
              });

              document.getElementById(`validate-test-${i}`).click();
              has_error = true;
              break;
            }
          }
        }

        if (has_error) {
          return;
        }

        data = data.filter((item) => item.value || item.type === "divider");
      }

      if (!this.button_loader) {
        this.button_loader = true;

        this.editLessonInformation({
          course_id: this.$route.params.id,
          id: this.lesson.id,
          data: {
            name: this.lesson.name,
            description: this.lesson.description,
            data: data,
          },
        })
          .then(() => {
            this.$router.push({ name: "CourseView" });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },

    startOnlineLesson() {
      this.show_mobile_action = false;
      if (!this.lesson.name) {
        this.setNotification({
          type: "error",
          name: "Ошибка сохранения",
          description: "Заполните название урока",
        });
        return;
      }

      if (!this.lesson.data.date) {
        this.setNotification({
          type: "error",
          name: "Ошибка сохранения",
          description: "Укажите дату и время начала",
        });
        return;
      }

      if (this.lesson.data.type === "service" && !this.lesson.data.link) {
        this.setNotification({
          type: "error",
          name: "Ошибка сохранения",
          description: "Укажите ссылку на трансляцию",
        });
        return;
      }

      this.showStartModal = true;
    },

    changeOnlineLessonStatus() {
      if (!this.button_loader) {
        this.button_loader = true;

        let data = structuredClone(this.lesson.data);
        data.status =
          this.lesson.data.status === "created" ? "started" : "ended";

        if (data.status === "ended" && data.webinar_room) {
          this.stopWebinar(data.webinar_room).then(() => {
            this.editLessonInformation({
              course_id: this.$route.params.id,
              id: this.lesson.id,
              data: {
                data: data,
              },
            }).finally(() => {
              this.button_loader = false;
            });
          });

          return;
        }

        this.editLessonInformation({
          course_id: this.$route.params.id,
          id: this.lesson.id,
          data: {
            data: data,
          },
        }).finally(() => {
          this.button_loader = false;
        });
      }
    },
  },
};
</script>

<template>
  <LessonPagePreview v-if="show_preview" @close="show_preview = false" />

  <StartOnlineLessonModal
    v-if="showStartModal"
    @close="showStartModal = false"
    :lesson="lesson"
  />

  <LessonActionsDrawer
    v-if="show_mobile_action && windowWidth < 1024"
    @close="show_mobile_action = false"
    @save="updateLesson"
    @preview="showPreview"
    @start-online-lesson="startOnlineLesson"
    @end-online-lesson="changeOnlineLessonStatus"
  />

  <header class="lesson-header">
    <div class="lesson-header__left">
      <router-link
        :to="{ name: 'CourseView' }"
        class="button-icon button-icon_medium button-icon_light"
      >
        <i class="icon icon-arrow-long-left" />
      </router-link>

      <div class="lesson-header__info">
        <span
          class="lesson-number lesson-number_small"
          v-if="getLessonNumber(lesson.id)"
        >
          {{ getLessonNumber(lesson.id).label }}
        </span>

        <h3>
          {{ lesson.name }}
        </h3>
      </div>
    </div>

    <div class="lesson-header__right">
      <nav>
        <button @click="showPreview" v-if="lesson.type !== 'test'">
          Предпросмотр
        </button>
        <button @click="updateLesson">Сохранить и закрыть</button>
        <button
          v-if="
            (lesson.type === 'online' || lesson.type === 'webinar') &&
            lesson.data.status === 'created'
          "
          @click="startOnlineLesson"
        >
          Запустить <i class="icon icon-play-circle" />
        </button>
        <button
          v-if="
            (lesson.type === 'online' || lesson.type === 'webinar') &&
            lesson.data.status === 'started'
          "
          @click="changeOnlineLessonStatus"
        >
          Завершить <i class="icon icon-circle-stop" />
        </button>
      </nav>

      <div class="lesson-header__right__actions">
        <button @click="show_mobile_action = true">
          <i class="icon icon-more" />
        </button>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.lesson-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0 ($base-space * 4);
  border-bottom: 1px solid #eaecf0;
  background-color: $background-light;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    padding: ($base-space * 3) ($base-space * 8);
  }

  @media (max-width: 767px) {
    padding: ($base-space * 3) ($base-space * 6);
  }

  @media (max-width: 424px) {
    padding: ($base-space * 3) ($base-space * 5);
  }

  @media (max-width: 374px) {
    padding: ($base-space * 3) ($base-space * 4);
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $base-space * 4;

    @media (max-width: 1023px) {
      gap: $base-space * 2;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    gap: $base-space * 2;

    @media (max-width: 1023px) {
      grid-template-columns: 24px 1fr;
    }

    > h3 {
      @include m-text-medium();
      color: $text-dark-primary;
      @include max-stroke(1);
      max-width: 220px;

      @media (max-width: 1023px) {
        max-width: 120px;
      }
    }
  }

  &__right {
    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $base-space;

      @media (max-width: 1023px) {
        display: none;
      }

      > button,
      a {
        padding: ($base-space * 7) ($base-space * 4);
        color: $text-dark-second;
        transition: ease-out 0.05s;
        @include m-text-regular();
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $base-space;

        > i {
          width: 24px;
          height: 24px;
          background-color: $text-dark-second;
        }

        &:hover {
          background-color: $background-ultralight-accent;
          color: $text-accent;

          > i {
            background-color: $text-accent;
          }
        }

        &:active {
          background-color: $background-light-accent;
          color: $text-accent;

          > i {
            background-color: $text-accent;
          }
        }
      }
    }

    &__actions {
      display: none;

      @media (max-width: 1023px) {
        display: flex;
        grid-gap: $base-space * 3;
        justify-content: flex-end;
        align-items: center;
      }

      button {
        width: 28px;
        height: 28px;

        > i {
          width: 100%;
          height: 100%;
          background-color: $text-dark-third;
        }
      }
    }
  }
}
</style>
