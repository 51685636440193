<script setup>
import AppSelector from "@/components/Ui/AppSelector.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import WebinarDeviceDisabled from "@/components/Webinars/WebinarDeviceDisabled.vue";

const route = useRoute();
const store = useStore();
const blockDevice = ref("");
const cameraVideo = ref(null);
const webinar = computed(() => store.state.webinar.webinar);

const isCameraAllowed = computed(() => store.state.webinar.isCameraAllowed);
const isEnumerateCameraDevices = computed(
  () => store.state.webinar.isEnumerateCameraDevices
);
const isAudioAllowed = computed(() => store.state.webinar.isAudioAllowed);
const isEnumerateAudioDevices = computed(
  () => store.state.webinar.isEnumerateAudioDevices
);

const cameraDevices = computed(() => store.state.webinar.cameraDevices);
const audioDevices = computed(() => store.state.webinar.audioDevices);

const selectedCameraDevice = computed(
  () => store.state.webinar.selectedCameraDevice
);
const selectedAudioDevice = computed(
  () => store.state.webinar.selectedAudioDevice
);

const selectedCamera = computed(() =>
  cameraDevices.value.find(
    (device) => device.deviceId === selectedCameraDevice.value
  )
);

const selectedMicro = computed(() =>
  audioDevices.value.find(
    (device) => device.deviceId === selectedAudioDevice.value
  )
);

const cameraStream = computed(() => store.state.webinar.cameraStream);

const changeMicroStatus = () => {
  if (!isEnumerateAudioDevices.value) {
    blockDevice.value = "micro";
    return;
  }

  store.dispatch("webinar/handleAudio");
};

const changeCameraStatus = () => {
  if (!isEnumerateCameraDevices.value) {
    blockDevice.value = "camera";
    return;
  }

  store.dispatch("webinar/handleVideo");
};

const joinRoom = () => {
  store.dispatch("webinar/loadWebinarToken", route.params.id).then(() => {
    store.dispatch("webinar/joinRoom");
  });
};

const selectMicro = (item) => {
  store.commit("webinar/SET_STATE", {
    state: "selectedAudioDevice",
    value: item.deviceId,
  });
};

const selectCamera = (item) => {
  store.commit("webinar/SET_STATE", {
    state: "selectedCameraDevice",
    value: item.deviceId,
  });
  store.dispatch("webinar/changeCamera", item.deviceId);
};

watch(cameraStream, (newValue) => {
  nextTick(() => (cameraVideo.value.srcObject = newValue));
});

onMounted(() => {
  if (isCameraAllowed.value) {
    store.dispatch("webinar/changeCamera");
  }
});
</script>

<template>
  <WebinarDeviceDisabled
    v-if="blockDevice"
    :device="blockDevice"
    @close="blockDevice = ''"
  />

  <main class="webinar-settings">
    <div class="webinar-settings__wrapper">
      <div class="webinar-name">
        <span><i class="icon icon-webinar-fill" /></span>
        <h4 v-if="webinar.courseUnit">{{ webinar.courseUnit.name }}</h4>
      </div>

      <div class="webinar-camera">
        <div class="webinar-actions">
          <button
            type="button"
            :class="{ active: isAudioAllowed }"
            @click="changeMicroStatus"
          >
            <i
              class="icon"
              :class="`${isAudioAllowed ? 'icon-micro-on' : 'icon-micro-off'}`"
            />
          </button>
          <button
            type="button"
            :class="{ active: isCameraAllowed }"
            @click="changeCameraStatus"
          >
            <i
              class="icon"
              :class="`${
                isCameraAllowed ? 'icon-camera-on' : 'icon-camera-off'
              }`"
            />
          </button>
        </div>
        <p v-if="!isEnumerateCameraDevices">Камера не найдена</p>
        <template v-else>
          <video
            v-if="isCameraAllowed"
            playsinline="true"
            ref="cameraVideo"
            autoplay=""
          />
          <p v-else>Камера выключена</p>
        </template>
      </div>

      <div
        class="webinar-devices"
        v-if="isEnumerateAudioDevices || isEnumerateCameraDevices"
      >
        <AppSelector
          v-if="isEnumerateAudioDevices && audioDevices.length"
          label="Микрофон"
          size="medium"
          :selected-option="selectedMicro"
          :options="audioDevices"
          option-name="label"
          option-value="deviceId"
          @select="selectMicro"
        />
        <AppSelector
          v-if="isEnumerateCameraDevices && cameraDevices.length"
          label="Камера"
          size="medium"
          :selected-option="selectedCamera"
          :options="cameraDevices"
          option-name="label"
          option-value="deviceId"
          @select="selectCamera"
        />
      </div>

      <button class="button button_large" @click="joinRoom">
        Присоединиться
      </button>
    </div>
  </main>
</template>

<style scoped lang="scss">
.webinar-settings {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  height: 100dvh;
  background: $background-ultralight-gray;

  @media (max-width: 767px) {
    background: $background-light;
    align-items: flex-start;
  }

  &__wrapper {
    width: 564px;
    padding: calc($base-space * 12);
    border-radius: 24px;
    background: $background-light;
    box-shadow: 0 4px 32px 0 rgba(7, 6, 52, 0.03);
    margin: auto;

    @media (max-width: 1023px) {
      padding: calc($base-space * 8);
    }

    @media (max-width: 767px) {
      box-shadow: none;
      padding: 16px;
      width: 100vw;
    }

    > button {
      margin: calc($base-space * 8) 0 0;
      width: 100%;

      @media (max-width: 767px) {
        margin: calc($base-space * 6) 0 0;
      }
    }
  }
}

.webinar-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: calc($base-space * 3);

  @media (max-width: 767px) {
    gap: calc($base-space * 2);
  }

  > span {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #00b67f;

    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
    }

    > i {
      width: 24px;
      height: 24px;
      background-color: $text-light-primary;

      @media (max-width: 767px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  > h4 {
    @include max-stroke(1);
    @include l-text-medium();
    color: $text-dark-primary;
  }
}

.webinar-camera {
  margin: calc($base-space * 8) 0 0;
  aspect-ratio: 16/9;
  border-radius: 12px;
  background: $background-main-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    margin: calc($base-space * 4) 0 0;
  }

  > p {
    text-align: center;
    @include l-text-medium();
    color: $text-light-primary;
  }

  > video {
    max-width: 100%;
    max-height: 100%;
  }
}

.webinar-devices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: calc($base-space * 3);
  margin: calc($base-space * 4) 0 0;

  @media (max-width: 767px) {
    flex-flow: column;
  }
}

.webinar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc($base-space * 2);
  position: absolute;
  top: 8px;
  right: 8px;

  > button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: $background-ultralight-gray;
    z-index: 2;
    transition: ease-out 0.05s;

    &:hover {
      background-color: $background-light-gray;
    }

    > i {
      width: 20px;
      height: 20px;
      background-color: $text-error;
    }

    &.active {
      background-color: #cff6e1;

      > i {
        background-color: rgba(0, 137, 63, 1);
      }

      &:hover {
        background-color: #b3e3c9;

        > i {
          background-color: rgba(0, 98, 45, 1);
        }
      }
    }
  }
}
</style>
