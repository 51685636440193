<script setup>
import AppModal from "@/components/Ui/AppModal.vue";
import { computed, defineEmits } from "vue";
import AppSelector from "@/components/Ui/AppSelector.vue";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);
const store = useStore();
const isEnumerateCameraDevices = computed(
  () => store.state.webinar.isEnumerateCameraDevices
);
const isEnumerateAudioDevices = computed(
  () => store.state.webinar.isEnumerateAudioDevices
);
const cameraDevices = computed(() => store.state.webinar.cameraDevices);
const audioDevices = computed(() => store.state.webinar.audioDevices);

const selectedCameraDevice = computed(
  () => store.state.webinar.selectedCameraDevice
);
const selectedAudioDevice = computed(
  () => store.state.webinar.selectedAudioDevice
);

const selectedCamera = computed(() =>
  cameraDevices.value.find(
    (device) => device.deviceId === selectedCameraDevice.value
  )
);

const selectedMicro = computed(() =>
  audioDevices.value.find(
    (device) => device.deviceId === selectedAudioDevice.value
  )
);

const selectMicro = (item) => {
  store.dispatch("webinar/changeStreamAudio", item.deviceId);
};

const selectCamera = (item) => {
  store.commit("webinar/SET_STATE", {
    state: "selectedCameraDevice",
    value: item.deviceId,
  });
  store.dispatch("webinar/changeStreamCamera", item.deviceId);
};
</script>

<template>
  <AppModal @close="emit('close')" size="medium">
    <div class="webinar-settings">
      <h3>Настройки трансляции</h3>
      <p>Выберите устройства записи звука и видео</p>

      <AppSelector
        v-if="isEnumerateAudioDevices && audioDevices.length"
        label="Микрофон"
        size="medium"
        :selected-option="selectedMicro"
        :options="audioDevices"
        option-name="label"
        option-value="deviceId"
        @select="selectMicro"
      />
      <AppSelector
        v-if="isEnumerateCameraDevices && cameraDevices.length"
        label="Камера"
        size="medium"
        :selected-option="selectedCamera"
        :options="cameraDevices"
        option-name="label"
        option-value="deviceId"
        @select="selectCamera"
      />

      <button class="button button_medium" @click="emit('close')">
        Готово
      </button>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.webinar-settings {
  width: 424px;

  > h3 {
    @include h4();
    color: $text-dark-primary;
  }

  > p {
    margin: calc($base-space * 4) 0 0;
    @include m-text-regular();
    color: $text-dark-second;
  }

  > .app-selector {
    &:first-of-type {
      margin: calc($base-space * 8) 0 0;
    }
    &:last-of-type {
      margin: calc($base-space * 4) 0 0;
    }
  }

  > button {
    margin: calc($base-space * 8) 0 0;
    width: 100%;
  }
}
</style>
