import API from "@/api";

const state = {
  lesson: {},
  test: "",
  testQuestionsCount: 0,
};

const mutations = {
  SET_LESSON_INFORMATION(store, payload) {
    store.lesson = payload;

    if (!store.lesson.data) {
      if (store.lesson.type === "online") {
        store.lesson.data = {
          link: "",
          date: "",
          time: { value: 45, name: "45 мин." },
          status: "created",
        };

        return;
      }

      if (store.lesson.type === "webinar") {
        store.lesson.data = {
          link: "",
          date: "",
          access: "public",
          type: "browser",
          time: { value: 45, name: "45 мин." },
          status: "created",
        };

        return;
      }

      store.lesson.data = [];
    }
  },

  CHANGE_LESSON_NAME(store, payload) {
    store.lesson.name = payload;
  },

  CHANGE_LESSON_DESCRIPTION(store, payload) {
    store.lesson.description = payload;
  },

  ADD_LESSON_TEMPLATE(store, payload) {
    if (!payload.type) {
      return;
    }

    switch (payload.type) {
      case "numeric-list":
      case "bullet-list":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: [""],
          });
          return;
        }

        store.lesson.data.push({ type: payload.type, value: [""] });
        break;

      case "footnote":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: "",
            style: "default",
          });
          return;
        }

        store.lesson.data.push({
          type: payload.type,
          value: "",
          style: "default",
        });
        break;

      case "image":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: [{ url: "", name: "" }],
          });
          return;
        }

        store.lesson.data.push({
          type: payload.type,
          value: [{ url: "", name: "" }],
        });
        break;

      case "test":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: {
              name: "",
              description: "",
              multiple: false,
              answers: ["", "", "", ""],
              selected: "",
            },
          });
          return;
        }

        store.lesson.data.push({
          type: payload.type,
          value: {
            name: "",
            description: "",
            multiple: false,
            answers: ["", "", "", ""],
            selected: "",
          },
        });
        break;

      case "question":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: { name: "", description: "", answers: ["", "", "", ""] },
          });
          return;
        }

        store.lesson.data.push({
          type: payload.type,
          value: { name: "", description: "", answers: ["", "", "", ""] },
        });
        break;

      case "divider":
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
          });
          return;
        }

        store.lesson.data.push({ type: payload.type });
        break;

      default:
        if (payload.index) {
          store.lesson.data.splice(payload.index - 1, 0, {
            type: payload.type,
            value: "",
          });
          return;
        }

        store.lesson.data.push({ type: payload.type, value: "" });
    }
  },

  COPY_LESSON_TEMPLATE(store, payload) {
    store.lesson.data.splice(payload.index, 0, payload.template);
  },

  DELETE_LESSON_TEMPLATE(store, payload) {
    store.lesson.data.splice(payload, 1);
  },

  UPDATE_LESSON_TEMPLATE(store, payload) {
    store.lesson.data[payload.index] = payload.template;
  },

  UPDATE_ONLINE_LESSON(store, payload) {
    store.lesson.data = payload;
  },

  CLEAR_LESSON(store) {
    store.lesson = null;

    store.test = null;
    store.testQuestionsCount = 0;
  },

  SET_TEST(store, payload) {
    store.test = payload;
  },

  SET_TEST_QUESTIONS(store, payload) {
    store.testQuestionsCount = payload;
  },
};

const actions = {
  loadLessonInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.course_id}/units/${data.lesson_id}`)
        .then((response) => {
          commit("SET_LESSON_INFORMATION", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editLessonInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.put(
        `api/partners/courses/${data.course_id}/units/${data.id}`,
        data.data
      )
        .then((response) => {
          commit("SET_LESSON_INFORMATION", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTestInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.lesson_id}/tests`
      )
        .then((response) => {
          commit("SET_TEST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateTestSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data.course_id}/units/${data.lesson_id}/tests`,
        data.data
      )
        .then((response) => {
          commit("SET_TEST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTestQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.lesson_id}/tests/questions`,
        { params: data.params }
      )
        .then((response) => {
          commit("SET_TEST_QUESTIONS", response.data.meta.total);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addTestQuestions(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data.course_id}/units/${data.lesson_id}/tests/questions/add`,
        { questions: data.questions }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createWebinarRoom(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data.courseId}/units/${data.unitId}/webinars/create`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteLesson(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/courses/${data.course_id}/units/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTestQuestion(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data.course_id}/units/${data.lesson_id}/tests/questions/delete`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
