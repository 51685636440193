import API from "@/api";
import { getDataTimeString, loadDevice } from "@/package/helpers/webinar";
import { socket } from "@/socket";

const state = {
  webinar: null,

  state: "init",

  // camera
  isCameraAllowed: false,
  isEnumerateCameraDevices: false,
  cameraDevices: [],
  selectedCameraDevice: null,
  cameraStream: null,

  // audio
  isAudioAllowed: false,
  isEnumerateAudioDevices: false,
  audioDevices: [],
  selectedAudioDevice: null,
  audioStream: null,

  // screenBroadcast
  isScreenBroadcastingAllowed: false,
  screenStream: null,

  participantsCounts: 0,
  participants: [],
  messages: [],

  token: null,

  device: null,
  producerTransport: null,

  producers: new Map(),
  producerLabel: new Map(),
};

const mutations = {
  SET_STATE(store, payload) {
    store[payload.state] = payload.value;
  },

  SET_DEVICES(store, payload) {
    store[payload.state].push(payload.value);
  },

  SET_WEBINAR_PARTICIPANTS(store, payload) {
    store.participants = JSON.parse(payload);
    store.participantsCounts = store.participants.length;
  },

  SET_WEBINAR_MESSAGE(store, payload) {
    let data = payload;
    const date = new Date();
    data.time = `${date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`;
    store.messages.unshift(data);
  },

  CLEAR_WEBINAR(store) {
    store.webinar = null;
    store.state = "init";
    store.isCameraAllowed = false;
    store.isEnumerateCameraDevices = false;
    store.cameraDevices = [];
    store.selectedCameraDevice = null;
    store.cameraStream = null;
    store.isAudioAllowed = false;
    store.isEnumerateAudioDevices = false;
    store.audioDevices = [];
    store.selectedAudioDevice = null;
    store.audioStream = null;
    store.isScreenBroadcastingAllowed = false;
    store.screenStream = null;
    store.participantsCounts = 0;
    store.participants = [];
    store.messages = [];
    store.token = null;
    store.device = null;
    store.producerTransport = null;
    store.producers = new Map();
    store.producerLabel = new Map();
  },
};

const actions = {
  loadWebinarInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/webinars/${data}`)
        .then((response) => {
          commit("SET_STATE", { state: "webinar", value: response.data.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  startWebinar({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/webinars/${data}/start`)
        .then((response) => {
          commit("SET_STATE", { state: "webinar", value: response.data.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  stopWebinar(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/webinars/${data}/end`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  recreateWebinarRoom(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/webinars/${data}/recreate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadWebinarToken({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/webinars/${data}/token`)
        .then((response) => {
          commit("SET_STATE", { state: "token", value: response.data.token });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  endWebinar({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/webinars/${data}/end`)
        .then((response) => {
          commit("SET_STATE", { state: "webinar", value: response.data.data });
          commit("SET_STATE", { state: "state", value: "end" });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async initEnumerateCameraDevices({ dispatch, commit }) {
    await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(async (stream) => {
        await dispatch("enumerateCameraDevices", stream);
        commit("SET_STATE", { state: "isCameraAllowed", value: true });
      })
      .catch(() => {
        commit("SET_STATE", { state: "isCameraAllowed", value: false });
      });
  },

  async enumerateCameraDevices({ commit, dispatch, state }, stream) {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (device.kind === "videoinput") {
            commit("SET_DEVICES", { state: "cameraDevices", value: device });
          }
        });

        if (state.cameraDevices.length) {
          commit("SET_STATE", {
            state: "selectedCameraDevice",
            value: state.cameraDevices[0].deviceId,
          });
        } else {
          commit("SET_STATE", { state: "isCameraAllowed", value: false });
        }
      })
      .then(async () => {
        await dispatch("stopTracks", stream);
        commit("SET_STATE", {
          state: "isEnumerateCameraDevices",
          value: true,
        });
      });
  },

  async initEnumerateAudioDevices({ dispatch, commit }) {
    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        await dispatch("enumerateAudioDevices", stream);
        commit("SET_STATE", { state: "isAudioAllowed", value: true });
      })
      .catch(() => {
        commit("SET_STATE", { state: "isAudioAllowed", value: false });
      });
  },

  async enumerateAudioDevices({ commit, dispatch, state }, stream) {
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (device.kind === "audioinput") {
            commit("SET_DEVICES", { state: "audioDevices", value: device });
          }
        });

        if (state.audioDevices.length) {
          commit("SET_STATE", {
            state: "selectedAudioDevice",
            value: state.audioDevices[0].deviceId,
          });
        } else {
          commit("SET_STATE", { state: "isAudioAllowed", value: false });
        }
      })
      .then(async () => {
        await dispatch("stopTracks", stream);
        commit("SET_STATE", { state: "isEnumerateAudioDevices", value: true });
      });
  },

  async stopTracks(_, stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  },

  handleVideo({ commit, state, dispatch }) {
    if (state.isCameraAllowed) {
      if (state.producerLabel.get("videoType")) {
        dispatch("closeProducer", "videoType");
      }
      dispatch("stopTracks", state.cameraStream);
    } else {
      dispatch("changeCamera");
    }

    commit("SET_STATE", {
      state: "isCameraAllowed",
      value: !state.isCameraAllowed,
    });
  },

  async handleAudio({ commit, state, dispatch }) {
    if (state.state === "room") {
      if (state.isAudioAllowed) {
        if (state.producerLabel.get("audioType")) {
          dispatch("closeProducer", "audioType");
        }
      } else {
        await dispatch("produce", {
          type: "audioType",
          device: state.selectedAudioDevice,
        });
      }
    }

    commit("SET_STATE", {
      state: "isAudioAllowed",
      value: !state.isAudioAllowed,
    });
  },

  async changeCamera({ commit, dispatch, state }, deviceId) {
    if (state.cameraStream) {
      await dispatch("stopTracks", state.cameraStream);
    }

    const videoConstraints = {
      audio: false,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        deviceId: deviceId,
        aspectRatio: 1.777,
      },
    };
    await navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((camStream) => {
        commit("SET_STATE", {
          state: "cameraStream",
          value: camStream,
        });
      });

    if (state.producerTransport) {
      await dispatch("produce", {
        type: "videoType",
        device: state.selectedCameraDevice,
      });
    }
  },

  async changeStreamCamera({ commit, dispatch, state }, deviceId) {
    if (state.cameraStream) {
      await dispatch("stopTracks", state.cameraStream);
    }

    const videoConstraints = {
      audio: false,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        deviceId: deviceId,
        aspectRatio: 1.777,
      },
    };
    await navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((camStream) => {
        commit("SET_STATE", {
          state: "cameraStream",
          value: camStream,
        });
      });

    if (state.producerTransport) {
      await dispatch("closeProducer", "videoType");
      await dispatch(
        "produce",
        {
          type: "videoType",
          device: state.selectedCameraDevice,
        },
        100
      );
    }
  },

  async changeStreamAudio({ commit, dispatch, state }, deviceId) {
    commit("SET_STATE", { state: "selectedAudioDevice", value: deviceId });

    if (state.isAudioAllowed) {
      await dispatch("closeProducer", "audioType");
      await dispatch(
        "produce",
        {
          type: "audioType",
          device: state.selectedAudioDevice,
        },
        100
      );
    }
  },

  async startScreenSharing({ commit, dispatch }) {
    await navigator.mediaDevices
      .getDisplayMedia({ audio: true, video: { frameRate: { ideal: 30 } } })
      .then((screenStream) => {
        commit("SET_STATE", {
          state: "isScreenBroadcastingAllowed",
          value: true,
        });
        commit("SET_STATE", {
          state: "screenStream",
          value: screenStream,
        });

        dispatch("produce", { type: "screenType" });
      })
      .catch(() => {});
  },

  async stopScreenSharing({ commit, dispatch }) {
    commit("SET_STATE", { state: "isScreenBroadcastingAllowed", value: false });
    commit("SET_STATE", { state: "screenStream", value: null });

    if (state.producerLabel.get("screenType")) {
      dispatch("closeProducer", "screenType");
      dispatch("closeProducer", "audioTab");
    }
  },

  async initDevices({ dispatch, commit }) {
    await dispatch("initEnumerateCameraDevices");
    await dispatch("initEnumerateAudioDevices");
    commit("SET_STATE", { state: "state", value: "settings" });
  },

  joinRoom({ commit, rootState, dispatch, state }) {
    socket.emit(
      "createRoom",
      {
        room_id: state.webinar.external_id,
      },
      () => {
        socket.emit(
          "join",
          {
            room_id: state.webinar.external_id,
            peer_info: {
              join_data_time: getDataTimeString(),
              peer_uuid: rootState.user.user.id,
              peer_id: rootState.user.user.id,
              peer_name: `${rootState.user.user.first_name} ${rootState.user.user.last_name}`,
              peer_token: state.token,
              peer_presenter: true,
            },
          },
          async (response) => {
            if (response === "invalid") {
              commit(
                "system/SET_NOTIFICATION",
                {
                  type: "error",
                  name: "Что-то пошло не так",
                  description: "Неверный индификатор комнаты",
                },
                { root: true }
              );
              return;
            }
            if (response === "notAllowed") {
              console.log(
                "00-WARNING ----> Room is Unauthorized for current user, please provide a valid room name for this user"
              );
              return;
            }
            if (response === "unauthorized") {
              commit(
                "system/SET_NOTIFICATION",
                {
                  type: "error",
                  name: "Что-то пошло не так",
                  description: "Не удалось авторизоваться в комнате",
                },
                { root: true }
              );
              return;
            }
            if (response === "isLocked") {
              console.log(
                "00-WARNING ----> Room is Locked, Try to unlock by the password"
              );
              return;
            }
            if (response === "isLobby") {
              console.log(
                "00-WARNING ----> Room Lobby Enabled, Wait to confirm my join"
              );
              return;
            }
            if (response === "isBanned") {
              console.log("00-WARNING ----> You are Banned from the Room!");
              return;
            }

            document.getElementById("usedesk-messenger").style.display = "none";
            commit("SET_STATE", { state: "state", value: "room" });

            dispatch("getRoomInfo");

            await dispatch("initSockets");

            socket.emit("getRouterRtpCapabilities", {}, async (response) => {
              let routerRtpCapabilities = response;

              routerRtpCapabilities.headerExtensions =
                routerRtpCapabilities.headerExtensions.filter(
                  (ext) => ext.uri !== "urn:3gpp:video-orientation"
                );

              await loadDevice(routerRtpCapabilities).then(async (device) => {
                commit("SET_STATE", { state: "device", value: device });
                await dispatch("initTransfers");
              });
            });
          }
        );
      }
    );
  },

  async initTransfers({ state, commit, dispatch }) {
    await socket.emit(
      "createWebRtcTransport",
      {
        forceTcp: false,
        rtpCapabilities: state.device.rtpCapabilities,
      },
      (response) => {
        if (response.error) {
          return;
        }

        commit("SET_STATE", {
          state: "producerTransport",
          value: state.device.createSendTransport(response),
        });

        state.producerTransport.on(
          "connect",
          async ({ dtlsParameters }, callback) => {
            try {
              await socket.emit(
                "connectTransport",
                {
                  transport_id: state.producerTransport.id,
                  dtlsParameters,
                },
                () => {
                  callback();
                }
              );
            } catch (e) {
              console.log(e);
            }
          }
        );

        state.producerTransport.on(
          "produce",
          async ({ kind, appData, rtpParameters }, callback, errback) => {
            try {
              await socket.emit(
                "produce",
                {
                  producerTransportId: state.producerTransport.id,
                  kind,
                  appData,
                  rtpParameters,
                },
                (response) => {
                  callback({
                    id: response.producer_id,
                  });
                }
              );
            } catch (e) {
              console.log(e);
              errback(e);
            }
          }
        );

        state.producerTransport.on("connectionstatechange", async (resp) => {
          if (resp === "failed") {
            state.producerTransport.close();
          }
        });

        state.producerTransport.on("icegatheringstatechange", async (resp) => {
          console.log(resp);
        });

        dispatch("startLocalMedia");
      }
    );
  },

  initSockets({ dispatch }) {
    socket.on("message", (data) => {
      dispatch("messageHandle", data);
    });
    socket.on("refreshParticipantsCount", (data) => {
      dispatch("handleRefreshParticipantsCount", data);
    });
    socket.on("removeMe", (data) => {
      dispatch("handleRemoveMe", data);
    });
    socket.on("updatePeerInfo", () => {
      dispatch("getRoomInfo");
    });
    socket.on("user_joined", () => {
      dispatch("getRoomInfo");
    });
  },

  handleRefreshParticipantsCount({ commit }, data) {
    commit("SET_STATE", {
      state: "participantsCounts",
      value: data.peer_counts,
    });
  },

  handleRemoveMe({ dispatch }, data) {
    dispatch("handleRefreshParticipantsCount", data);
    dispatch("getRoomInfo");
  },

  getRoomInfo({ commit }) {
    socket.emit("getRoomInfo", {}, (room) => {
      commit("SET_WEBINAR_PARTICIPANTS", room.peers);
    });
  },

  async sendMessage({ commit, rootState, state }, message) {
    const data = {
      room_id: state.webinar.external_id,
      peer_id: rootState.user.user.id,
      peer_name: `${rootState.user.user.first_name} ${rootState.user.user.last_name}`,
      to_peer_id: "all",
      to_peer_name: "all",
      peer_msg: message,
    };

    socket.emit("message", data, (response) => {
      if (response.data === "ok") {
        commit("SET_WEBINAR_MESSAGE", data);
      }
    });
  },

  messageHandle({ commit }, data) {
    commit("SET_WEBINAR_MESSAGE", data);
  },

  async startLocalMedia({ state, dispatch }) {
    if (state.isAudioAllowed) {
      await dispatch("produce", {
        type: "audioType",
        device: state.selectedAudioDevice,
      });
    }

    if (state.isCameraAllowed) {
      await dispatch("produce", {
        type: "videoType",
        device: state.selectedCameraDevice,
      });
    }
  },

  async produce({ commit, state, dispatch }, payload) {
    let audio = false;
    switch (payload.type) {
      case "audioType":
        audio = true;
        break;
      case "videoType":
        commit("SET_STATE", { state: "isCameraAllowed", value: true });
        break;
      case "screenType":
        break;
      default:
        return;
    }

    let stream;
    try {
      if (payload.type === "videoType") {
        stream = state.cameraStream;
      }

      if (payload.type === "screenType") {
        stream = state.screenStream;
      }

      if (payload.type === "audioType") {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            deviceId: state.selectedAudioDevice,
          },
          video: false,
        });
      }

      const track = audio
        ? stream.getAudioTracks()[0]
        : stream.getVideoTracks()[0];

      let params = {
        track,
        appData: {
          mediaType: payload.type,
        },
      };

      const producer = await state.producerTransport.produce(params);

      if (payload.type === "screenType" && stream.getAudioTracks()[0]) {
        dispatch("produceScreenStreamAudio");
      }

      state.producers.set(producer.id, producer);
      state.producerLabel.set(payload.type, producer.id);

      producer.on("trackended", () => {
        dispatch("closeProducer", payload.type);
      });

      producer.on("transportclose", () => {
        dispatch("closeProducer", payload.type);
      });

      producer.on("close", () => {
        dispatch("closeProducer", payload.type);
      });

      return producer;
    } catch (err) {
      console.error("Produce error:", err);
    }
  },

  async produceScreenStreamAudio({ state, dispatch }) {
    try {
      const track = state.screenStream.getAudioTracks()[0];
      const params = {
        track,
        appData: {
          mediaType: "audioTab",
        },
      };

      const producer = await state.producerTransport.produce(params);

      state.producers.set(producer.id, producer);
      state.producerLabel.set("audioTab", producer.id);

      producer.on("trackended", () => {
        dispatch("closeProducer", "audioTab");
      });

      producer.on("transportclose", () => {
        dispatch("closeProducer", "audioTab");
      });

      producer.on("close", () => {
        dispatch("closeProducer", "audioTab");
      });
    } catch (err) {
      console.error("Produce error:", err);
    }
  },

  closeProducer({ state }, payload) {
    if (!state.producerLabel.has(payload)) {
      return console.warn(payload);
    }

    const producer_id = state.producerLabel.get(payload);
    const data = {
      peer_name: this.peer_name,
      producer_id: producer_id,
      type: payload,
      status: false,
    };

    socket.emit("producerClosed", data);
    state.producers.get(producer_id).close();
    state.producers.delete(producer_id);
    state.producerLabel.delete(payload);
  },

  clearWebinar({ commit }) {
    commit("CLEAR_WEBINAR");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
