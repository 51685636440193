<script>
export default {
  name: "LessonTestTemplate",

  props: {
    test: {
      type: Object,
    },

    index: {
      type: Number,
    },
  },

  data() {
    return {
      selected_answers: "",
      answer_success: null,
      show_error: false,
    };
  },

  watch: {
    selected_answers() {
      this.show_error = false;
    },
  },

  methods: {
    answerStyles(index) {
      if (this.answer_success !== null) {
        if (this.test.multiple) {
          return {
            "answer-result_success":
              this.test.selected.indexOf(`value-${index}`) >= 0 &&
              this.selected_answers.indexOf(`value-${index}`) >= 0,
            "answer-result_error":
              this.test.selected.indexOf(`value-${index}`) === -1 &&
              this.selected_answers.indexOf(`value-${index}`) >= 0,
            "answer-result_true":
              this.test.selected.indexOf(`value-${index}`) >= 0,
            "answer-result_false":
              this.test.selected.indexOf(`value-${index}`) === -1,
          };
        }

        return {
          "answer-result_success":
            this.selected_answers === this.test.selected &&
            this.selected_answers === `value-${index}`,
          "answer-result_error":
            this.selected_answers !== this.test.selected &&
            this.selected_answers === `value-${index}`,
          "answer-result_true": `value-${index}` === this.test.selected,
          "answer-result_false": `value-${index}` !== this.test.selected,
        };
      }

      return "";
    },

    answerIcons(index) {
      if (this.test.multiple) {
        return {
          "icon-done": this.test.selected.indexOf(`value-${index}`) >= 0,
          "icon-close-circle":
            this.test.selected.indexOf(`value-${index}`) === -1,
        };
      }

      return {
        "icon-done": `value-${index}` === this.test.selected,
        "icon-close-circle": `value-${index}` !== this.test.selected,
      };
    },

    checkAnswer() {
      if (!this.selected_answers.length) {
        this.show_error = true;
        return;
      }

      if (this.test.multiple) {
        if (
          this.selected_answers.filter(
            (item) => this.test.selected.indexOf(item) !== -1
          ).length === this.test.selected.length &&
          !this.selected_answers.filter(
            (item) => this.test.selected.indexOf(item) === -1
          ).length
        ) {
          this.answer_success = true;
          return;
        }

        this.answer_success = false;

        return;
      }

      if (this.selected_answers === this.test.selected) {
        this.answer_success = true;
      }

      if (this.selected_answers !== this.test.selected) {
        this.answer_success = false;
      }
    },
  },

  beforeMount() {
    if (this.test.multiple) {
      this.selected_answers = [];
    }
  },
};
</script>

<template>
  <section class="template-test">
    <h5>{{ test.name }}</h5>

    <p v-if="test.description">{{ test.description }}</p>

    <div class="template-test__answers">
      <template v-for="(answer, i) in test.answers" :key="i">
        <template v-if="answer_success === null">
          <label
            v-if="test.multiple"
            :for="`answer-${index}-${i}`"
            class="app-checkbox answer"
            :class="{
              answer_selected: selected_answers.includes(`value-${i}`),
            }"
          >
            <input
              type="checkbox"
              :id="`answer-${index}-${i}`"
              v-model="selected_answers"
              :value="`value-${i}`"
            />

            <div />

            {{ answer }}
          </label>

          <label
            v-else
            :for="`answer-${index}-${i}`"
            class="app-radio answer"
            :class="{ answer_selecte: selected_answers === `value-${i}` }"
          >
            <input
              type="radio"
              :id="`answer-${index}-${i}`"
              v-model="selected_answers"
              :value="`value-${i}`"
            />

            <div />

            {{ answer }}
          </label>
        </template>

        <p v-else class="answer-result" :class="answerStyles(i)">
          {{ answer }}

          <i class="icon" :class="answerIcons(i)" />
        </p>
      </template>
    </div>

    <span v-if="show_error" class="error">Выберите вариант ответа</span>

    <button
      class="button button_secondary button_medium"
      type="button"
      v-if="answer_success === null"
      @click="checkAnswer"
    >
      Проверить
    </button>

    <button
      type="button"
      class="text-button text-button_medium"
      @click="answer_success = null"
      v-if="answer_success === false"
    >
      Попробовать снова <i class="icon icon-retry" />
    </button>
  </section>
</template>

<style scoped lang="scss">
.template-test {
  margin: ($base-space * 2) (-$base-space * 10);
  padding: ($base-space * 10);
  border-radius: 16px;
  background-color: $background-ultralight-gray;

  @media (max-width: 1023px) {
    margin: ($base-space * 2) 0;
    padding: ($base-space * 4);
  }

  &:first-child {
    margin: 0 (-$base-space * 10) ($base-space * 2);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 2);
    }
  }

  > h5 {
    @include l-text-regular();
    color: $text-dark-primary;
  }

  > p {
    margin: ($base-space) 0 0;
    color: $text-dark-second;
    @include m-text-regular();
    white-space: pre-wrap;
  }

  &__answers {
    margin: ($base-space * 6) 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: $base-space * 2;

    @media (max-width: 1023px) {
      margin: ($base-space * 4) 0 0;
    }
  }

  > span {
    display: block;
    padding: 0;
    margin: ($base-space * 2) 0 0;
  }

  > button {
    margin: ($base-space * 6) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 4) 0 0;
    }

    &.button {
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
}

.answer {
  @include m-text-regular();
  padding: ($base-space * 5);
  background-color: $background-light;
  border-radius: 16px;
  border: 1px solid $background-ultralight-gray;

  &:hover {
    border-color: $stroke-fourth;

    > div {
      border-color: $stroke-primary;
    }

    > input[type="radio"] {
      &:checked {
        & ~ div {
          border-color: $background-main-accent;
        }
      }
    }
  }

  &_selecte {
    border-color: $stroke-accent;

    &:hover {
      border-color: $stroke-accent;
    }
  }

  &_selected {
    border-color: $stroke-accent;

    &:hover {
      border-color: $stroke-accent-dark;

      > div {
        background-color: $stroke-accent-dark;
        border-color: $stroke-accent-dark;
      }
    }
  }
}

.answer-result {
  padding: ($base-space * 4) ($base-space * 5);
  background-color: $background-light;
  border-radius: 16px;
  border: 1px solid $background-ultralight-gray;
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: $base-space * 2;
  align-items: center;

  > i {
    width: 32px;
    height: 32px;
  }

  &_error {
    border-color: $text-error;

    &:hover {
      border-color: $text-error;
    }

    &:focus-within {
      border-color: $text-error;
    }

    > i {
      background-color: $text-error;
    }
  }

  &_success {
    border-color: $text-success;

    &:hover {
      border-color: $text-success;
    }

    &:focus-within {
      border-color: $text-success;
    }

    > i {
      background-color: $text-success;
    }
  }

  &_true {
    > i {
      background-color: $text-success;
    }
  }

  &_false {
    > i {
      background-color: $text-error;
    }
  }
}
</style>
