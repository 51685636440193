import API from "@/api";

const state = {
  course: "",
};

const mutations = {};

const actions = {
  loadPartnerOnlineUnits(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/online`, {
        params: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadOnlineCourses(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/online/courses`, {
        params: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
