import { createStore } from "vuex";
import system from "./system.store";
import user from "./user.store";
import courses from "./courses.store";
import course from "./course.store";
import lesson from "./lesson.store";
import tasks from "./tasks.store";
import questions from "./questions.store";
import analytics from "./analytics.store";
import webinar from "./webinar.store";
import online from "./online.store";

export default createStore({
  modules: {
    system,
    user,
    courses,
    course,
    lesson,
    tasks,
    questions,
    analytics,
    webinar,
    online,
  },
});
