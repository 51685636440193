export const COURSE_STATUSES = [
  { id: "hidden", name: "Скрыт", icon: "hide" },
  { id: "in_moderation", name: "На модерации", icon: "speach" },
  { id: "planned", name: "Запланирован", icon: "cloke-history" },
  { id: "started", name: "Запущен", icon: "play-circle" },
  { id: "ended", name: "Завершен", icon: "done" },
  { id: "banned", name: "Заблокирован", icon: "block" },
];

export const LESSON_TYPES = [
  {
    id: "lesson",
    name: "Урок",
    icon: "acts",
    description:
      "Делитесь теоретическим материалом с помощью удобного конструктора",
  },
  {
    id: "task",
    name: "Задание",
    icon: "test",
    description: "Создавайте задания и проверяйте решения учеников вручную",
  },
  {
    id: "test",
    name: "Тест",
    icon: "checkbox",
    description:
      "Проводите тестирования знаний учеников и анализируйте результаты",
  },
  {
    id: "webinar",
    name: "Вебинар",
    icon: "webinar",
    description:
      "Проводите видео-лекции для учеников в онлайн-формате с демонстрацией экрана",
  },
  {
    id: "online",
    name: "Онлайн-встреча",
    icon: "live",
    description: "Планируйте встречи для общения с учениками в режиме онлайн",
  },
  {
    id: "module",
    name: "Модуль",
    description:
      "Объединяйте уроки, задания и онлайн-встречи в отдельные группы",
  },
];

export const TASK_STATUSES = [
  {
    id: "unreviewed",
    name: "Не проверено",
    icon: "eye",
  },
  {
    id: "rejected",
    name: "Не зачтено",
    icon: "close-circle",
  },
  {
    id: "accepted",
    name: "Зачтено",
    icon: "done",
  },
];
