import * as mediasoupClient from "mediasoup-client";

export function getDataTimeString() {
  const d = new Date();
  const date = d.toISOString().split("T")[0];
  const time = d.toTimeString().split(" ")[0];
  return `${date}-${time}`;
}

export async function loadDevice(routerRtpCapabilities) {
  let device;
  try {
    device = new mediasoupClient.Device();
  } catch (error) {
    if (error.name === "UnsupportedError") {
      console.error("Browser not supported");
    } else {
      console.error("Browser not supported: ", error);
    }
  }
  await device.load({
    routerRtpCapabilities,
  });

  return device;
}
