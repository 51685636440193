<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppDrawer",

  emits: ["close"],

  props: {
    title: {
      type: String,
    },
  },
});
</script>

<template>
  <div class="app-drawer" @click="$emit('close')">
    <div class="app-drawer__wrapper" @click.stop>
      <button @click="$emit('close')">
        <i class="icon icon-close" />
      </button>

      <h3 v-if="title">{{ title }}</h3>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-drawer {
  background: $background-main-overlay;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    align-items: flex-end;
    justify-content: center;
  }

  &__wrapper {
    background: $background-light;
    padding: ($base-space * 8);
    border-radius: 24px;
    position: relative;

    @media (max-width: 1023px) {
      width: calc(100vw - 64px);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6) ($base-space * 6) ($base-space * 12);
      border-radius: 16px 16px 0 0;
      max-height: 100vh;
      overflow-y: auto;
      overscroll-behavior: contain;
      width: 100%;
    }

    @media (max-width: 424px) {
      padding: ($base-space * 6) ($base-space * 5) ($base-space * 12);
    }

    @media (max-width: 374px) {
      padding: ($base-space * 6) ($base-space * 4) ($base-space * 12);
    }

    > button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: none;
      border: none;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-dark-third;
      }
    }

    > h3 {
      @include h4();
      color: $text-dark-primary;
      width: calc(100% - 32px);
      word-break: break-word;
    }
  }
}
</style>
