<script>
import { defineComponent } from "vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "LessonActionsDrawer",

  components: { AppDrawer },

  props: {
    actions: {
      type: Array,
    },
  },

  emits: ["close", "save", "preview", "startOnlineLesson", "endOnlineLesson"],

  computed: {
    ...mapState({
      lesson: (state) => state.lesson.lesson,
    }),
  },
});
</script>

<template>
  <AppDrawer @close="$emit('close')" :title="lesson.name">
    <div class="drawer__content">
      <button @click="$emit('preview')">
        <i class="icon icon-preview" /> Предпросмотр
      </button>

      <button @click="$emit('save')">
        <i class="icon" :class="`icon-save`" /> Сохранить и закрыть
      </button>

      <button
        v-if="
          (lesson.type === 'online' || lesson.type === 'webinar') &&
          lesson.data.status === 'created'
        "
        @click="$emit('startOnlineLesson')"
      >
        <i class="icon icon-play-circle" /> Запустить
      </button>
      <button
        v-if="
          (lesson.type === 'online' || lesson.type === 'webinar') &&
          lesson.data.status === 'started'
        "
        @click="$emit('endOnlineLesson')"
      >
        <i class="icon icon-circle-stop" /> Завершить
      </button>
    </div>

    <div class="drawer__actions">
      <button
        class="button button_secondary button_large"
        @click="$emit('close')"
      >
        Отмена
      </button>
    </div>
  </AppDrawer>
</template>

<style scoped lang="scss"></style>
